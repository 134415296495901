import Constants from "@/web/constants";
import QuizModel from "@/web/store/models/QuizModel";
import QuizResultModel from "@/web/store/models/QuizResultModel";
import QuizAnswerModel from "@/web/store/models/QuizAnswerModel";
import QuizQuestionModel from "@/web/store/models/QuizQuestionModel";

export const state = () => ({
  statuses: [],
  errors: [],
});

export const types = {
  LOAD_QUIZZES: "loadQuizzes",
  LOAD_QUIZZES_SUCCESS: "loadQuizzesSuccess",
  LOAD_QUIZZES_ERROR: "loadQuizzesError",
};

export const mutations = {
  [types.LOAD_QUIZZES](state, componentId) {
    const statuses = {...state.statuses};
    statuses[componentId] = Constants.STATUS_LOADING;
    state.statuses = statuses;
  },
  [types.LOAD_QUIZZES_SUCCESS](state, componentId) {
    const statuses = {...state.statuses};
    statuses[componentId] = Constants.STATUS_LOADED;
    state.statuses = statuses;
  },
  [types.LOAD_QUIZZES_ERROR](state, {componentId, error}) {
    const statuses = {...state.statuses};
    const errors = {...state.errors};
    errors[componentId] = error;
    statuses[componentId] = Constants.STATUS_ERROR;
    state.statuses = statuses;
    state.errors = errors;
  },
};
export const actions = {
  async loadQuizzes({rootState, commit, getters}, componentId) {
    if (!getters["isComponentLoading"](componentId)) {
      const eventId = rootState.eventId;
      commit(types.LOAD_QUIZZES, componentId);
      const localQuizIds = QuizModel.query()
        .where("event_component_id", componentId)
        .get()
        .map(it => it.id);

      return QuizModel.api()
        .get(`events/${eventId}/components/${componentId}/survey_quizzes`)
        .then(result => {
          const remoteQuizIds = result.response.data.map(quiz => quiz.id);
          let idsToDelete = localQuizIds.filter(quizId => !remoteQuizIds.includes(quizId));
          if (idsToDelete.length) {
            QuizModel.delete(quiz => idsToDelete.includes(quiz.id));
          }
          commit(types.LOAD_QUIZZES_SUCCESS, componentId);
          return result;
        })
        .catch(error => {
          commit(types.LOAD_QUIZZES_ERROR, {componentId, error});
          throw error;
        });
    } else {
      return Promise.resolve();
    }
  },

  async loadQuizResult({commit, rootState}, {componentId, quizId}) {
    const eventId = rootState.eventId;
    return QuizResultModel.api().get(`events/${eventId}/components/${componentId}/survey_quiz/${quizId}/responses`);
  },

  async submitQuiz({rootState, rootGetters, commit}, {componentId, quizId}) {
    const eventId = rootState.eventId;
    const allResponses = rootGetters["quizAnswers/allResponses"];
    const requestBody = mapResponsesToRequestBody(allResponses);
    return QuizResultModel.api()
      .post(`events/${eventId}/components/${componentId}/survey_quiz/${quizId}/responses`, requestBody)
      .then(result => {
        QuizModel.update({
          where: quizId,
          data: {
            is_completed_by_me: true,
          },
        });
      });
  },

  async submitILoveMarketingRating({rootState, rootGetters, commit}, {componentId, quizId, response}) {
    const eventId = rootState.eventId;
    const requestBody = {responses: [response]};
    return QuizResultModel.api()
      .post(`events/${eventId}/components/${componentId}/survey_quiz/${quizId}/responses`, requestBody)
      .then(result => {
        QuizModel.update({
          where: quizId,
          data: {
            is_completed_by_me: true,
          },
        });
      });
  },

  async clearQuizzes({commit}) {
    return await Promise.all([
      QuizModel.deleteAll(),
      QuizQuestionModel.deleteAll(),
      QuizAnswerModel.deleteAll(),
      QuizResultModel.deleteAll(),
    ]);
  },
};

const mapResponsesToRequestBody = responses => {
  const requestResponses = responses.map(response => {
    return {
      question: response.questionId,
      answer: response.answerId,
      response: response.responseText,
    };
  });
  return {responses: requestResponses};
};

export const getters = {
  getById: state => quizId => {
    return QuizModel.query().whereId(quizId).withAllRecursive().first();
  },
  getAllFromComponent: state => componentId => {
    if (componentId) {
      return QuizModel.query().where("event_component_id", componentId).withAll().orderBy("order").get();
    }
    return [];
  },

  getAll(state) {
    return QuizModel.query().withAll().orderBy("order").get();
  },

  getResult: state => quizId => {
    const userScore = QuizResultModel.query().where("survey_quiz_id", quizId).first();
    return {
      score: userScore ? userScore.points : 0,
      totalCorrectAnswers: userScore ? userScore.all_points : 0,
    };
  },

  uncompletedQuizzesCount() {
    return QuizModel.query().where("is_completed_by_me", false).count();
  },

  isComponentLoaded: state => componentId => state.statuses[componentId] === Constants.STATUS_LOADED,

  isComponentLoading: state => componentId => state.statuses[componentId] === Constants.STATUS_LOADING,

  isAnyLoading: state => Object.values(state.statuses).some(status => status === Constants.STATUS_LOADING),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
