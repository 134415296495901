import {Model} from "@vuex-orm/core";

export default class QuizResultModel extends Model {
  static entity = "quiz_results";

  static fields() {
    return {
      id: this.attr(null),
      survey_quiz_id: this.number(-1),
      points: this.number(0),
      all_points: this.number(0),
    };
  }
}
