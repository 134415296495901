import { Constants } from "@/web/constants";

export const state = () => ({

});

export const types = {
  // LOAD_ACCESSES: "loadAccesses",
  // LOAD_ACCESSES_SUCCESS: "loadAccessesSuccess",
  // LOAD_ACCESSES_ERROR: "loadAccessesError",
};

export const mutations = {
  // [types.LOAD_ACCESSES](state) {
  //   state.accessesStatus = Constants.STATUS_LOADING;
  // },
  // [types.LOAD_ACCESSES_SUCCESS](state) {
  //   state.accessesStatus = Constants.STATUS_LOADED;
  // },
  // [types.LOAD_ACCESSES_ERROR](state, error) {
  //   state.accessesStatus = Constants.STATUS_ERROR;
  //   state.accessesError = error;
  // },
};
export const actions = {
  // async fetchAccesses({ commit }, { eventId }) {
  //   commit(types.LOAD_ACCESSES);
  //   return ExhibitorModeratorModel.api()
  //     .get(`events/${eventId}/moderator/accesses`)
  //     .then(accesses => {
  //       commit(types.LOAD_ACCESSES_SUCCESS);
  //     })
  //     .catch(err => {
  //       commit(types.LOAD_ACCESSES_ERROR, err);
  //       throw err;
  //     });
  // },
};
export const getters = {
  // isLoaded(state) {
  //   return state.status === Constants.STATUS_LOADED;
  // },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
