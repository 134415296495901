import {Model} from "@vuex-orm/core";
import AgendaSessionPathModel from "@/web/store/models/AgendaSessionPathModel";
import EventDayModel from "@/web/store/models/EventDayModel";

export default class BusinessMatchingSessionModel extends Model {
  static entity = "bm_sessions";

  static fields() {
    return {
      id: this.attr(null),
      event_component_id: this.number(-1),
      title: this.string(""),
      time_start: this.string(""),
      time_end: this.string(""),
      time_period: this.number(0),
      order: this.number(0),
      agenda_session_day_id: this.attr(null),
      agenda_session_day: this.belongsTo(EventDayModel, "agenda_session_day_id"),
      tag_agenda_places_ids: this.attr([]),
      tag_agenda_places: this.hasManyBy(AgendaSessionPathModel, "tag_agenda_places_ids"),
    };
  }
}
