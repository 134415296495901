import axios from "@/web/services/axios";
import { Constants } from "@/web/constants";

export const types = {
  LOAD_DATA: "loadSurveyResults",
  LOAD_DATA_SUCCESS: "loadSurveyResultsSuccess",
  LOAD_DATA_ERROR: "loadSurveyResultsError"
};

export const state = {
  status: null,
  error: null,
  surveyResults: [],
  componentName: ""
};

export const getters = {
  isLoaded(state) {
    return state.status === Constants.STATUS_LOADED;
  },
  hasLoadingError(state) {
    return state.status === Constants.STATUS_ERROR;
  }
};

export const mutations = {
  [types.LOAD_DATA](state) {
    state.status = Constants.STATUS_LOADING;
  },
  [types.LOAD_DATA_SUCCESS](state) {
    state.status = Constants.STATUS_LOADED;
  },
  [types.LOAD_DATA_ERROR](state, error) {
    state.status = Constants.STATUS_ERROR;
    state.error = error;
  },
  setSurveyResults(state, surveyResults) {
    state.surveyResults = surveyResults.responses;
    state.componentName = surveyResults.name;
  }
};

export const actions = {
  async loadSurvey({ commit }, uuid) {
    const url = `/survey_responses_external/${uuid}`;

    return axios.get(url).then(
      response => {
        const survey = response.data;
        commit("setSurveyResults", survey);
        commit(types.LOAD_DATA_SUCCESS);
        return Promise.resolve(survey);
      },
      err => {
        commit(types.LOAD_DATA_ERROR, err);
        return Promise.reject(err);
      }
    );
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
