import { Constants } from "@/web/constants";
import InboxMessageModel from "@/web/store/models/InboxMessageModel";
import InboxThreadModel from "@/web/store/models/InboxThreadModel";

const API_LIMIT = 20;

export const types = {
  LOAD_MESSAGES: "loadMessages",
  LOAD_MESSAGES_SUCCESS: "loadMessagesSuccess",
  LOAD_MESSAGES_ERROR: "loadMessagesError",
};

export const state = () => ({
  status: {},
  error: {},
  meta: {},
});

export const mutations = {
  setMeta(state, {threadId, meta, lastMessageTimestamp}) {
    const newMeta = {...state.meta};
    newMeta[threadId] = {allItems: meta.all_items, isMore: meta.is_more, lastMessageTimestamp};
    state.meta = newMeta;
  },

  resetMeta(state, threadId) {
    const newMeta = {...state.meta};
    delete newMeta[threadId];
    state.meta = newMeta;
  },

  [types.LOAD_MESSAGES](state, threadId) {
    const status = {...state.status};
    status[threadId] = Constants.STATUS_LOADING;
    state.status = status;
  },
  [types.LOAD_MESSAGES_SUCCESS](state, threadId) {
    const status = {...state.status};
    status[threadId] = Constants.STATUS_LOADED;
    state.status = status;
  },
  [types.LOAD_MESSAGES_ERROR](state, {threadId, error}) {
    const status = {...state.status};
    const errors = {...status.errors};
    errors[threadId] = error;
    status[threadId] = Constants.STATUS_ERROR;
    state.status = status;
    state.error = errors;
  },
};

export const actions = {
  async loadMessagesPage({commit, state, getters}, {threadId}) {
    const params = {
      limit: API_LIMIT,
      timestamp: getters.getLastMessageTimestamp(threadId),
    };
    if (state.status[threadId] !== Constants.STATUS_LOADING && getters.getIsMore(threadId)) {
      commit(types.LOAD_MESSAGES, threadId);
      return await InboxMessageModel.api()
        .get(`inbox/threads/${threadId}/messages`, {
          params: params,
          dataTransformer: ({data, headers}) => {
            if (data.inbox_messages.length) {
              const lastMessageDate = data.inbox_messages.slice(-1).pop().created_at;
              const lastMessageTimestamp = new Date(lastMessageDate).getTime();
              commit("setMeta", {
                threadId: threadId,
                meta: data.meta,
                lastMessageTimestamp: Number.parseInt(lastMessageTimestamp),
              });
            } else {
              commit("setMeta", {threadId: threadId, meta: data.meta, lastMessageTimestamp: null});
            }
            return data.inbox_messages;
          },
        })
        .then(result => {
          commit(types.LOAD_MESSAGES_SUCCESS, threadId);
        })
        .catch(err => {
          commit(types.LOAD_MESSAGES_ERROR, {threadId: threadId, error: err});
        });
    } else {
      return Promise.resolve();
    }
  },
  async refreshMessagesFromThread({commit, state, dispatch}, {threadId}) {
    const params = {
      limit: API_LIMIT,
    };
    return await InboxMessageModel.api()
      .get(`inbox/threads/${threadId}/messages/update`, {
        params: params,
        dataTransformer: ({data, headers}) => {
          if (data.inbox_messages.length) {
            const lastMessageDate = data.inbox_messages.slice(-1).pop().created_at;
            const lastMessageTimestamp = new Date(lastMessageDate).getTime();
            commit("setMeta", {threadId: threadId, meta: data.meta, lastMessageTimestamp: lastMessageTimestamp});
          } else {
            commit("setMeta", {threadId: threadId, meta: data.meta, lastMessageTimestamp: null});
          }
          return data.inbox_messages;
        },
      })
      .then(result => {
        const lastMessage = result.response.data.inbox_messages.slice(0, 1).pop();
        InboxThreadModel.update({
          where: lastMessage.inbox_thread_id,
          data: {
            message_id: lastMessage.id,
          },
        }).then(inboxThread => {
          if (inboxThread) {
            inboxThread.message = lastMessage;
            commit("notifications/setInboxThreadNotification", inboxThread, {root: true});
          }
        });
      });
  },
};

export const getters = {
  messagesFromThread: state => threadId => {
    // eslint-disable-next-line
    return InboxMessageModel
      .query()
      .where("inbox_thread_id", threadId)
      .withAllRecursive()
      .all()
      .reduce((acc, currentItem) => {
        currentItem.timeVisible = true;
        currentItem.dateVisible = true;
        currentItem.avatarVisible = true;

        if (acc.length > 0) {
          const previousItem = acc[acc.length - 1];

          const currentItemDate = new Date(currentItem.created_at);
          const previousItemDate = new Date(previousItem.created_at);

          const diffInMilliSeconds = Math.abs(currentItemDate - previousItemDate) / 1000;
          const minutes = Math.floor(diffInMilliSeconds / 60) % 60;

          //Show time only when the time difference between neighbour messages is larger than 10 minutes
          if (minutes < 10 && previousItem.user_id === currentItem.user_id) {
            currentItem.timeVisible = false;
          }

          //Show date only in messages from different day
          if (
            currentItemDate.getDate() === previousItemDate.getDate() &&
            currentItemDate.getMonth() === previousItemDate.getMonth() &&
            currentItemDate.getFullYear() === previousItemDate.getFullYear()
          ) {
            currentItem.dateVisible = false;
          }

          //Show avatar only when neighbour messages are not from the same user and it doesn't have visible date
          if (previousItem.user_id === currentItem.user_id && !currentItem.dateVisible) {
            currentItem.avatarVisible = false;
          }
        }
        return [...acc, currentItem];
      }, []);
  },

  unreadMessagesFromThread: state => (threadId, currentUserUuid) => {
    const thread = InboxThreadModel.query().whereId(threadId).first();
    if (thread) {
      const readAtDate = new Date(thread.read_at);
      return InboxMessageModel.query()
        .where(
          message =>
            message.inbox_thread_id === threadId &&
            (!thread.read_at || (new Date(message.updated_at) > readAtDate && currentUserUuid !== message.user_id))
        )
        .count();
    } else {
      return 0;
    }
  },

  getIsLoading: state => threadId => state.status[threadId] === Constants.STATUS_LOADING,

  getLastMessageTimestamp: state => threadId => state.meta[threadId] && state.meta[threadId].lastMessageTimestamp,

  getIsMore: state => threadId => {
    const threadMeta = state.meta[threadId];
    if (threadMeta) {
      return threadMeta.isMore;
    } else {
      return true;
    }
  },

  getStatus: state => threadId => state.status[threadId],
};

export default {
  namespaced: true,
  state: state,
  mutations,
  actions,
  getters,
};
