import {Constants} from "@/web/constants";
import BusinessMatchingSessionModel from "@/web/store/models/BusinessMatchingSessionModel";
import ComponentModel from "@/web/store/models/ComponentModel";

export const state = () => ({
  statuses: {},
  errors: {},
});

export const types = {
  LOAD_BM: "loadBusinessMatching",
  LOAD_BM_SUCCESS: "loadBusinessMatchingSuccess",
  LOAD_BM_ERROR: "loadBusinessMatchingError",
};

export const mutations = {
  [types.LOAD_BM](state, componentId) {
    const statuses = {...state.statuses};
    statuses[componentId] = Constants.STATUS_LOADING;
    state.statuses = statuses;
  },
  [types.LOAD_BM_SUCCESS](state, componentId) {
    const statuses = {...state.statuses};
    statuses[componentId] = Constants.STATUS_LOADED;
    state.statuses = statuses;
  },
  [types.LOAD_BM_ERROR](state, {componentId, error}) {
    const statuses = {...state.statuses};
    const errors = {...state.errors};
    errors[componentId] = error;
    statuses[componentId] = Constants.STATUS_ERROR;
    state.statuses = statuses;
    state.errors = errors;
  },
};

export const actions = {
  async loadBusinessMatchingComponent({commit, getters, rootGetters}, componentId) {
    if (!getters["isComponentLoading"](componentId)) {
      const component = rootGetters["components/getComponentById"](componentId);
      if (component) {
        const eventId = component.event_id;
        const businessMatchingType = component.additional_info.business_matching_type;
        commit(types.LOAD_BM, componentId);

        const localSessionIds = getters["getAllSessionsFromComponent"](componentId).map(it => it.id);

        return BusinessMatchingSessionModel.api()
          .get(`events/${eventId}/components/${componentId}/business_matching`)
          .then(result => {
            const remoteSessionIds = result.response.data.map(session => session.id);
            let idsToDelete = localSessionIds.filter(sessionId => !remoteSessionIds.includes(sessionId));
            if (idsToDelete.length) {
              BusinessMatchingSessionModel.delete(session => idsToDelete.includes(session.id));
            }
            commit(types.LOAD_BM_SUCCESS, componentId);
            return result;
          })
          .catch(error => {
            commit(types.LOAD_BM_ERROR, {componentId, error});
            throw error;
          });
      }
    }
    return Promise.resolve();
  },
};

export const getters = {
  getAllSessionsFromComponent: state => componentId => {
    if (componentId) {
      return BusinessMatchingSessionModel.query().where("event_component_id", componentId).withAll().orderBy("order").all();
    }
    return [];
  },

  getAllSessionFromEventDay: state => eventDay => {
    if (eventDay) {
      return BusinessMatchingSessionModel.query()
        .whereHas('agenda_session_day', (query) => {
          query.where(sessionEventDay => {
            const sessionDate = new Date(sessionEventDay.only_date);
            const dayDate = new Date(eventDay.only_date);
            return sessionDate.getFullYear() === dayDate.getFullYear() && sessionDate.getMonth() === dayDate.getMonth() && sessionDate.getDay() === dayDate.getDay();
          });
        })
        .orderBy(session => -new Date(session.time_start))
        .all();
    }
    return [];
  },

  isComponentLoaded: state => componentId => state.statuses[componentId] === Constants.STATUS_LOADED,

  isComponentLoading: state => componentId => state.statuses[componentId] === Constants.STATUS_LOADING,

  isAnyLoading: state => Object.values(state.statuses).some(status => status === Constants.STATUS_LOADING),

  isVideoCallEnabledInComponent: state => componentId => {
    let component = ComponentModel.query().whereId(componentId).first();
    if (component) {
      return component.additional_info.is_video_call_enabled;
    }
    return false;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
