import Pusher from "pusher-js";
import { Constants } from "@/web/constants";

const APP_KEY = process.env.VUE_APP_PUSHER_API_KEY;
const APP_CLUSTER = "eu";

let pusherSocket = null;
let userChannel = null;
let eventChannel = null;

export const state = {
  isFallback: false,
  userChannel: null,
};

export const mutations = {
  setFallback(state, isFallback) {
    state.isFallback = isFallback;
  },

  setUserChannel(state, userChannel) {
    state.userChannel = userChannel;
  },
};

export const actions = {
  async startPusherListening({ commit, dispatch, rootState }) {
    const userId = rootState.currentUser.userUuid;
    const eventId = rootState.eventId;

    if (!pusherSocket) {
      pusherSocket = new Pusher(APP_KEY, {
        cluster: APP_CLUSTER,
      });
      pusherSocket.connection.bind("connected", () => dispatch("stopFallbackContinuousLoading"));
      pusherSocket.connection.bind("error", () => dispatch("startFallbackContinuousLoading"));
    }

    if (eventId && !eventChannel) {
      const channelId = `event-${eventId}`;
      eventChannel = pusherSocket.subscribe(channelId);
      eventChannel.bind(Constants.PUSHER_USER_NOTIFICATIONS_UPDATED, ev => {
        const notificationId = ev.item_id;
        dispatch(
          "notifications/refreshNotifications",
          {
            showNotification: true,
            notificationId: notificationId,
          },
          { root: true }
        );
      });

      eventChannel.bind(Constants.PUSHER_EVENT_COMPONENT_LIST_UPDATED, ev => {
        dispatch("loadComponents", null, { root: true });
      });

      eventChannel.bind(Constants.PUSHER_EVENT_COMPONENT_UPDATED, ev => {
        if (ev.activity === Constants.PUSHER_ACTION_QUIZ_CREATED || ev.activity === Constants.PUSHER_ACTION_QUIZ_DELETED) {
          const componentId = ev.component_id;
          dispatch("quizzes/loadQuizzes", componentId, { root: true });
        } else if (
          ev.activity === Constants.PUSHER_ACTION_SESSION_QUESTION_CREATED ||
          ev.activity === Constants.PUSHER_ACTION_SESSION_QUESTION_DELETED ||
          ev.activity === Constants.PUSHER_ACTION_SESSION_QUESTION_CREATED_ADMIN
        ) {
          const componentId = ev.component_id;
          const agendaSessionId = ev.item_id;
          dispatch("agendaSessionQuestions/fetchQuestions", { componentId, agendaSessionId, isUpdateForAdmin: ev.activity === Constants.PUSHER_ACTION_SESSION_QUESTION_CREATED_ADMIN }, { root: true });
        }
      });

      eventChannel.bind(Constants.PUSHER_EVENT_FEED_WALL_UPDATED, ev => {
        const activity = ev.activity.toLowerCase();
        if (activity.includes(Constants.PUSHER_FEEDWALL)) {
          dispatch("sideMenu/handleFeedWallUpdateRequest", ev, { root: true });
        }
      });
    }

    if (userId && !userChannel) {
      const channelId = `user-${userId}`;
      userChannel = pusherSocket.subscribe(channelId);

      userChannel.bind(Constants.PUSHER_USER_NOTIFICATIONS_UPDATED, ev => {
        const notificationId = ev.item_id;
        dispatch(
          "notifications/refreshNotifications",
          {
            showNotification: true,
            notificationId: notificationId,
          },
          { root: true }

        );
      });

      userChannel.bind(Constants.PUSHER_INBOX_UPDATED, ev => {
        dispatch("inboxThreads/refreshInboxThreads", null, { root: true });
        if (ev.activity === Constants.PUSHER_INBOX_THREAD_UPDATED_ACTION) {
          dispatch("inboxMessages/refreshMessagesFromThread", { threadId: ev.thread_id }, { root: true });
        }
      });

      // channel.bind(Constants.PUSHER_USER_FRIENDS_UPDATED, ev => {
      //Not handled
      // });

      userChannel.bind(Constants.PUSHER_USER_FRIEND_INVITATIONS_UPDATED, ev => {
        dispatch("friends/loadInvitations", { showNotification: true }, { root: true });
        dispatch(
          "notifications/refreshNotifications",
          {
            showNotification: false,
          },
          { root: true }
        );
      });

      userChannel.bind(Constants.PUSHER_USER_BUSINESS_MATCHING, ev => {
        const componentId = Number.parseInt(ev.component_id);
        dispatch("businessMatchingMeetings/fetchMeetingsFromComponent", {componentId: componentId}, {root: true});
      });
    }
  },

  async stopPusherListening({ getters }) {
    if (userChannel) {
      pusherSocket.unsubscribe(userChannel.name);
      userChannel = null;
    }
  },

  async startFallbackContinuousLoading({ commit, dispatch }) {
    await commit("setFallback", true);
    dispatch("continuousLoading");
  },

  async stopFallbackContinuousLoading({ commit }) {
    await commit("setFallback", false);
  },

  async continuousLoading({ getters, dispatch }) {
    dispatch("inboxThreads/refreshInboxThreads", null, { root: true });
    if (getters.isFallback) {
      setTimeout(() => dispatch("inboxThreads/refreshInboxThreads", null, { root: true }), 30000);
    }
  },
};

export const getters = {
  isFallback(state) {
    return state.isFallback;
  },

  getUserChannel(state) {
    return state.userChannel;
  },
};

export default {
  namespaced: true,
  state: state,
  mutations,
  actions,
  getters,
};
