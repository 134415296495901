import Constants from "@/web/constants";
import { generateModulesFromComponentList } from "@/shared/utils";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import { getFieldValueOrDefault } from "@/shared/mixins/LpConfigMixin";
import FormBuilderConstants from "@/web/views/dev/formbuilder/form-builder-constants";
import dayjs from '@/shared/utils/day';
import EventCustomFormModel from "@/web/store/models/EventCustomFormModel";

export function lpSettings(state, getters) {
  const landingPageEditMode = getters["adminPanel/livePreviewEnabled"];
  if (landingPageEditMode) {
    return getters["adminPanel/currentConfig"];
  } else {
    return state.settings;
  }
}

export function requiresLogin(state) {
  const restrictedModes = ["close", "whitelist"];
  const mode = state.event.access_mode_type;

  return restrictedModes.includes(mode);
}

export function requiresAccessCode(state) {
  return !!state.event.access_code_on;
}

export function hasAccess(state, getters) {
  const requiresLogin = getters["requiresLogin"];
  const isJoined = getters["auth/isJoined"];
  if (requiresLogin && !isJoined) {
    return false;
  }

  const requiresAccessCode = getters["requiresAccessCode"];
  const isValid = getters["accessCode/isValid"];
  if (requiresAccessCode && !isValid) {
    return false;
  }

  return true;
}

export function hasDisabledAuth(state) {
  const event = state.event;
  if(event.id === 1226) {
    return false;
  } else if( event.id === 1185) {
    return true;
  } else {
    return event && event.access_mode_type === "none" || event.id === 1185;
  }
}

export function moduleByComponentId(state, getters) {
  return function (componentId) {
    const modules = getters["lpModules"];
    return modules.find(module => compareComponentId(module, componentId));
  };
}

function compareComponentId(module, componentId) {
  const ids = [].concat(module.component_id);
  return ids.includes(componentId);
}

const moduleValidators = {
  [Constants.MODULE_COMPONENT_AGENDA](module, components, event) {
    // Agenda module requires at least one agenda component
    return components.some(component => component.component_name === "AgendaComponent");
  },
  [Constants.MODULE_COMPONENT_EXHIBITORS]: hasComponentId,
  [Constants.MODULE_COMPONENT_PARTNERS]: hasComponentId,
  [Constants.MODULE_COMPONENT_SPEAKERS]: hasComponentId,
  [Constants.MODULE_QUIZZES]: hasComponentId,
  [Constants.MODULE_ATTENDEES]: hasComponentId,
  [Constants.MODULE_COMPONENT_LIVE_WEBINAR]: hasOptionalComponentId,
  [Constants.MODULE_IFRAME]: hasOptionalComponentId,
  [Constants.MODULE_IFRAME_NATIVE]: hasOptionalComponentId,
  [Constants.MODULE_SOCIAL_LINKS]: hasOptionalComponentId,
  [Constants.MODULE_TWITTER]: hasOptionalComponentId,
  [Constants.MODULE_EVENT_DESCRIPTION]: hasOptionalComponentId,
  [Constants.MODULE_MOBILE_APP_INFO]: hasOptionalComponentId,
  [Constants.MODULE_EVENT_TICKETS](module, components, event) {
    return hasOptionalComponentId(module, components, event) && isEventNotFinished(module, components, event);
  },
  [Constants.MODULE_CUSTOMER_SUPPORT]: hasOptionalComponentId,
  [Constants.MODULE_BUTTON_POPUP]: hasOptionalComponentId,
  [Constants.MODULE_EVENT_TIMER]: hasOptionalComponentId,
  [Constants.MODULE_FAQ]: hasOptionalComponentId,
  [Constants.MODULE_FEED_WALL]: () => false,
  [Constants.MODULE_QUIZZES]: (module, components) => module.visible && hasComponentId(module, components),
  [Constants.MODULE_ATTENDEES]: (module, component) => module.visible && hasComponentId(module, component),
  [Constants.MODULE_BUSINESS_MATCHING]: hasComponentId,
  ["srk_module"]: hasOptionalComponentId,
};

const navbarValidators = {
  [Constants.MODULE_COMPONENT_AGENDA](module, components, event) {
    // Agenda module requires at least one agenda component
    return components.some(component => component.component_name === "AgendaComponent");
  },
  [Constants.MODULE_COMPONENT_EXHIBITORS]: hasComponentId,
  [Constants.MODULE_COMPONENT_PARTNERS]: hasComponentId,
  [Constants.MODULE_COMPONENT_SPEAKERS]: hasComponentId,
  [Constants.MODULE_QUIZZES]: hasComponentId,
  [Constants.MODULE_ATTENDEES]: hasComponentId,
  [Constants.MODULE_COMPONENT_LIVE_WEBINAR]: hasOptionalComponentId,
  [Constants.MODULE_IFRAME]: hasOptionalComponentId,
  [Constants.MODULE_IFRAME_NATIVE]: hasOptionalComponentId,
  [Constants.MODULE_SOCIAL_LINKS]: hasOptionalComponentId,
  [Constants.MODULE_TWITTER]: hasOptionalComponentId,
  [Constants.MODULE_EVENT_DESCRIPTION]: hasOptionalComponentId,
  [Constants.MODULE_MOBILE_APP_INFO]: hasOptionalComponentId,
  [Constants.MODULE_EVENT_TICKETS](module, components, event) {
    return hasOptionalComponentId(module, components, event) && isEventNotFinished(module, components, event);
  },
  [Constants.MODULE_CUSTOMER_SUPPORT]: hasOptionalComponentId,
  [Constants.MODULE_BUTTON_POPUP]: hasOptionalComponentId,
  [Constants.MODULE_EVENT_TIMER]: hasOptionalComponentId,
  [Constants.MODULE_FAQ]: hasOptionalComponentId,
  [Constants.MODULE_FEED_WALL]: () => false,
  [Constants.MODULE_BUSINESS_MATCHING]: hasComponentId,
  ["srk_module"]: hasOptionalComponentId,
};

function hasComponentId(module, components, event) {
  const ids = [].concat(module.component_id);
  return components.some(component => ids.includes(component.id));
}

function hasOptionalComponentId(module, components, event) {
  if ("component_id" in module && module.component_id !== null && typeof module.component_id !== "undefined") {
    return hasComponentId(module, components);
  } else {
    return true;
  }
}

function isEventNotFinished(module, components, event) {
  return dayjs(event.end_date) > dayjs();
}

export function lpModules(state, getters) {
  const modules = getters["lpSettings"][LpConfigConstants.ROOT_FIELDS.MODULES];
  if (modules && modules.length) {
    return modules;
  } else {
    const components = getters["components/allComponents"];
    const tickets = getters['eventTickets/getAllTickets'];
    return generateModulesFromComponentList(state.event, tickets, components);
  }
}

export function visibleModules(state, getters) {
  const event = state.event;
  const modules = getters["lpModules"];
  const components = getters["components/allComponents"];
  const isAuthorized = getters["auth/isAuthorized"];

  if (modules && modules.length) {
    return modules
      .filter(module => {
        const validator = moduleValidators[module.type];
        return validator
          ? validator(module, components, event) && moduleValidator(module, isAuthorized)
          : moduleValidator(module, isAuthorized);
      })
      .sort((a, b) => a.order - b.order);
  }
}

export function navbarModules(state, getters) {
  const event = state.event;
  const modules = getters["lpModules"];
  const components = getters["components/allComponents"];
  const isAuthorized = getters["auth/isAuthorized"];

  if (modules && modules.length) {
    return modules
      .filter(module => {
        const validator = navbarValidators[module.type];
        return validator
          ? validator(module, components, event) && moduleValidator(module, isAuthorized)
          : moduleValidator(module, isAuthorized);
      })
      .sort((a, b) => a.order - b.order);
  }
}

function moduleValidator(module, isAuthorized) {
  let validators = [];
  if (Constants.MODULE_FIELD.ONLY_FOR_LOGGED_IN in module) {
    validators.push(
      (module, isAuthorized) =>
        !module[Constants.MODULE_FIELD.ONLY_FOR_LOGGED_IN] || (module[Constants.MODULE_FIELD.ONLY_FOR_LOGGED_IN] && isAuthorized)
    );
  }
  if (Constants.MODULE_FIELD.ONLY_FOR_GUESTS in module) {
    validators.push(
      (module, isAuthorized) =>
        !module[Constants.MODULE_FIELD.ONLY_FOR_GUESTS] || (module[Constants.MODULE_FIELD.ONLY_FOR_GUESTS] && !isAuthorized)
    );
  }
  if (Constants.MODULE_FIELD.VISIBLE in module) {
    validators.push((module, isAuthorized) => module[Constants.MODULE_FIELD.VISIBLE]);
  }
  return validators.map(it => it(module, isAuthorized)).every(result => result);
}

export function attendeesModule(state, getters) {
  const modules = getters["lpModules"];
  const components = getters["components/allComponents"];

  return modules.find(module => module.type === Constants.MODULE_ATTENDEES && hasComponentId(module, components));
}

export function feedWallModules(state, getters) {
  const modules = getters["lpModules"];
  const components = getters["components/allComponents"];

  return modules.filter(module => module.type === Constants.MODULE_FEED_WALL && hasComponentId(module, components));
}

export function quizModules(state, getters) {
  const modules = getters["lpModules"];
  const components = getters["components/allComponents"];

  return modules.filter(module => module.type === Constants.MODULE_QUIZZES && hasComponentId(module, components));
}

export function businessMatchingModules(state, getters) {
  const modules = getters["lpModules"];
  const components = getters["components/allComponents"];

  return modules.filter(module => module.type === Constants.MODULE_BUSINESS_MATCHING && hasComponentId(module, components));
}

export function eventTicketModules(state, getters) {
  const modules = getters["visibleModules"];
  return modules.filter(module => module.type === Constants.MODULE_EVENT_TICKETS);
}

export function mobileAppModules(state, getters) {
  const modules = getters["visibleModules"];
  return modules.filter(module => module.type === Constants.MODULE_MOBILE_APP_INFO);
}

export function speakerModules(state, getters) {
  const modules = getters["visibleModules"];
  return modules.filter(module => module.type === Constants.MODULE_COMPONENT_SPEAKERS);
}

export function helpModule(state, getters) {
  return getters["lpSettings"][LpConfigConstants.ROOT_FIELDS.HELP];
}

export function socialLoginVisible(state, getters) {
  const customRegisterForm = getters["customRegisterFormData"];
  const socialLoginVisible = getFieldValueOrDefault(customRegisterForm, FormBuilderConstants.SOCIAL_LOGIN_VISIBLE, true);
  return state.event.access_mode_type !== "whitelist" && socialLoginVisible;
}

export function appleClientId(state, getters) {
  const customRegisterForm = getters["customRegisterFormData"];
  return getFieldValueOrDefault(customRegisterForm, FormBuilderConstants.APPLE_CLIENT_ID, Constants.APPLE_CLIENT_ID);
}

export function facebookAppId(state, getters) {
  const customRegisterForm = getters["customRegisterFormData"];
  return getFieldValueOrDefault(customRegisterForm, FormBuilderConstants.FACEBOOK_APP_ID, Constants.FACEBOOK_API_KEY);
}

export function inboxEnabled(state, getters) {
  const inboxConfig = getFieldValueOrDefault(getters["lpSettings"], LpConfigConstants.ROOT_FIELDS.INBOX, {});
  const inboxEnabled = getFieldValueOrDefault(inboxConfig, LpConfigConstants.INBOX_FIELDS.ENABLED, true);
  const allComponents = getters["components/allComponents"];
  return inboxEnabled && hasOptionalComponentId(inboxConfig, allComponents);
}

export function agendaRatingPopupConfig(state, getters) {
  const features = getters["lpSettings"][LpConfigConstants.ROOT_FIELDS.FEATURES];
  if (features && features[LpConfigConstants.FEATURES_FIELDS.AGENDA_RATING_POPUP]) {
    return features[LpConfigConstants.FEATURES_FIELDS.AGENDA_RATING_POPUP];
  } else {
    return {};
  }
}

export function autoLogOutEnabled(state) {
  return !!state.settings.auto_log_out_enabled;
}

export function eventLogoWithOutline(state, getters) {
  const lpSettings = getters["lpSettings"];
  return !(
    lpSettings &&
    lpSettings[LpConfigConstants.ROOT_FIELDS.NAVBAR] &&
    lpSettings[LpConfigConstants.ROOT_FIELDS.NAVBAR][LpConfigConstants.NAVBAR_FIELDS.LOGO_WITH_OUTLINE] === false
  );
}

export function helpEnabled(state, getters) {
  return !(
    getters["lpSettings"][LpConfigConstants.ROOT_FIELDS.HELP] &&
    getters["lpSettings"][LpConfigConstants.ROOT_FIELDS.HELP][LpConfigConstants.HELP_FIELDS.ENABLED] === false
  );
}

export function paymentConfig(state, getters) {
  return getters["lpSettings"][LpConfigConstants.ROOT_FIELDS.PAYMENT] || {};
}

export function currentTime(state) {
  return state.currentTime;
}

export function customRegisterFormData(state, getters) {
  const customRegisterForm = getFieldValueOrDefault(getters["lpSettings"], LpConfigConstants.ROOT_FIELDS.REGISTER_FORM, {});
  if (customRegisterForm && Object.keys(customRegisterForm).length === 0) {
    return null;
  } else {
    return customRegisterForm;
  }
}

export function requiredUserMetaFields(state) {
  if (state.settings.register_form) {
    const fields = state.settings.register_form.fields;
    if (fields && fields.length) {
      return fields.filter(field => field.required).map(field => field.id);
    }
  }
  return [];
}

export function eventTicketsVat(state) {
  return state.event && state.event.tax * 0.01;
}
//
// export function ticketSalesTerms(state) {
//   return (state.settings && state.settings.ticket_sales_terms) || {};
// }
//
// export function footerConfig(state) {
//   return (state.settings && state.settings.footer) || {};
// }

export function customSeparator(state, getters) {
  const lpSettings = getters["lpSettings"];
  return (
    (lpSettings &&
      lpSettings[LpConfigConstants.ROOT_FIELDS.FEATURES] &&
      lpSettings[LpConfigConstants.ROOT_FIELDS.FEATURES][LpConfigConstants.FEATURES_FIELDS.CUSTOM_SEPARATOR]) ||
    null
  );
}

export function customTicketForms(state, getters) {
  if(state.eventId === 1251) {
    const lpSettings = getters["lpSettings"];
    return lpSettings && lpSettings[LpConfigConstants.ROOT_FIELDS.TICKET_FORMS];
  } else {
    return EventCustomFormModel.query().withAllRecursive().all()
      .reduce((acc, val) => {
        val.event_tickets.forEach(ticket => {
          acc[ticket.id] = JSON.parse(val.json_config);
        });
        return acc;
      }, {});
  }
}

export function lpConfiguratorEnabled(state, getters) {
  return !!state.event.lp_configurator_enabled;
}

export function themeType(state, getters) {
  const lpSettings = getters["lpSettings"];
  const theme = lpSettings[LpConfigConstants.ROOT_FIELDS.THEME];
  return theme && theme[LpConfigConstants.THEME_FIELDS.THEME_TYPE];
}

export function sideMenuType(state, getters) {
  const lpSettings = getters["lpSettings"];
  const theme = lpSettings[LpConfigConstants.ROOT_FIELDS.THEME];
  return (theme && theme[LpConfigConstants.THEME_FIELDS.SIDE_MENU_TYPE]) || LpConfigConstants.SIDE_MENU_TYPES.BUBBLES;
}
