import {Model} from "@vuex-orm/core";

export default class QuizAnswerModel extends Model {
  static entity = "quiz_answers";

  static fields() {
    return {
      id: this.attr(null),
      survey_quiz_question_id: this.number(-1),
      mode: this.number(-1),
      order: this.number(-1),
      answer: this.string(null).nullable(),
    };
  }
}
