import {Model} from "@vuex-orm/core";
import AgendaSessionPathModel from "@/web/store/models/AgendaSessionPathModel";

export default class AgendaSessionDisplayModel extends Model {
  static entity = "agendaDisplaySessions";

  static fields() {
    return {
      id: this.attr(null),
      event_component_id: this.attr(null),
      event_id: this.attr(null),
      name: this.string(""),
      time_start: this.string(""),
      time_end: this.string(""),
      tag_agenda_place_id: this.attr(null),
      tag_agenda_place: this.belongsTo(AgendaSessionPathModel, "tag_agenda_place_id"),
    };
  }
}
