import {Model} from "@vuex-orm/core";
import QuizQuestionModel from "@/web/store/models/QuizQuestionModel";

export default class QuizModel extends Model {
  static entity = "quizzes";

  static fields() {
    return {
      id: this.attr(null),
      event_component_id: this.number(-1),
      mode: this.string(""),
      order: this.number(-1),
      visible_results: this.boolean(false),
      name: this.string(""),
      questions_count: this.number(-1),
      is_completed_by_me: this.boolean(false),
      is_login_required: this.boolean(true),
      updated_at: this.string(""),
      quiz_question_ids: this.attr([]),
      survey_quiz_questions: this.hasManyBy(QuizQuestionModel, "quiz_question_ids"),
    };
  }
}
