import facebookService from "@/web/services/auth/facebook";

export const state = () => ({
  accessToken: null
});

export const mutations = {
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken;
  },

  clearAccessToken(state) {
    state.accessToken = null;
  }
};

export const actions = {
  async requestFacebookToken({ commit }, appId) {
    const res = await facebookService.login(appId);
    const accessToken = res.accessToken;
    commit("setAccessToken", accessToken);
  },

  async logout({ commit, state }) {
    if (state.accessToken) {
      await facebookService.logout();
      commit("clearAccessToken");
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
