import {Model} from "@vuex-orm/core";
import AgendaSessionPathModel from "@/web/store/models/AgendaSessionPathModel";
import BusinessMatchingSessionModel from "@/web/store/models/BusinessMatchingSessionModel";
import UserModel from "@/web/store/models/UserModel";
import BusinessMatchingFilterGroup from "@/web/store/models/BusinessMatchingFilterGroup";

export default class BusinessMatchingFilterTag extends Model {
  static entity = "bm_filter_tags";

  static fields() {
    return {
      id: this.attr(null),
      business_matching_filter_group_id: this.attr(null),
      business_matching_filter_group: this.belongsTo(BusinessMatchingFilterGroup, "business_matching_filter_group"),
      value: this.string(""),
      hex: this.string(""),
      order: this.number(-1),
    };
  }
}
