import {Model} from "@vuex-orm/core";

export default class FeedWallChannelModel extends Model {
  static entity = "feed_wall_channels";

  static fields() {
    return {
      id: this.attr(null),
      event_component_id: this.number(-1),
      name: this.string(""),
      default: this.boolean(false),
    };
  }
}
