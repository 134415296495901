<template>
  <div class="modal-card">
    <section class="modal-card-head modal-card-body modal-card-foot has-text-centered">
      <div class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
        <div class="modal-card-title mb-0">{{ text }}</div>
        <div style="min-height: 120px" class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center has-fullwidth is-relative">
          <transition name="fade" mode="out-in">
            <b-loading :is-full-page="false" :active="true" v-if="loadingVisible"></b-loading>
            <success-icon class="success-icon has-fill-primary" v-else></success-icon>
          </transition>
        </div>
        <progress class="progress is-primary is-small" :style="progressBarStyle" :value="progress" max="100"></progress>
      </div>
    </section>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import Constants from "@/web/constants";
import SuccessIcon from "@/assets/tickets/icon_tick_circle.svg";

export default {
  name: "AuthLoadingModal",

  components: { SuccessIcon },

  computed: {
    ...mapState("auth", ["authLoadingStatus"]),

    Constants: () => Constants,

    text() {
      if (this.authLoadingStatus === Constants.AUTH_LOADING_STATUSES.LOGGING_IN) {
        return this.$t("system.auth_loading_logging_in");
      } else if (this.authLoadingStatus === Constants.AUTH_LOADING_STATUSES.SIGNING_UP) {
        return this.$t("system.auth_loading_signing_up");
      } else if(this.authLoadingStatus === Constants.AUTH_LOADING_STATUSES.SUBMITTING_REQUIRED_DATA) {
        return this.$t("system.auth_loading_submitting_required_data");
      } else if (this.authLoadingStatus === Constants.AUTH_LOADING_STATUSES.JOINING_EVENT) {
        return this.$t("system.auth_loading_joining_the_event");
      } else if (this.authLoadingStatus === Constants.AUTH_LOADING_STATUSES.DOWNLOADING_USER_DATA) {
        return this.$t("system.auth_loading_downloading_user_data");
      } else if (this.authLoadingStatus === Constants.AUTH_LOADING_STATUSES.DOWNLOADING_EVENT_DATA) {
        return this.$t("system.auth_loading_downloading_event_data");
      } else if (this.authLoadingStatus === Constants.AUTH_LOADING_STATUSES.SUCCESS) {
        return this.$t("system.auth_loading_success");
      } else {
        return "";
      }
    },

    progress() {
      if (
        this.authLoadingStatus === Constants.AUTH_LOADING_STATUSES.LOGGING_IN ||
        this.authLoadingStatus === Constants.AUTH_LOADING_STATUSES.SIGNING_UP
      ) {
        return 10;
      } else if (this.authLoadingStatus === Constants.AUTH_LOADING_STATUSES.JOINING_EVENT) {
        return 20;
      } else if (this.authLoadingStatus === Constants.AUTH_LOADING_STATUSES.DOWNLOADING_USER_DATA) {
        return 40;
      } else if (this.authLoadingStatus === Constants.AUTH_LOADING_STATUSES.DOWNLOADING_EVENT_DATA) {
        return 60;
      } else if (this.authLoadingStatus === Constants.AUTH_LOADING_STATUSES.SUCCESS) {
        return 100;
      } else {
        return 0;
      }
    },

    loadingVisible() {
      return this.authLoadingStatus !== Constants.AUTH_LOADING_STATUSES.SUCCESS;
    },

    progressBarStyle() {
      return {
        height: "8px",
        width: "238px",
      };
    },
  },

  methods: {
    ...mapMutations("auth", ["setAuthLoadingStatus"]),
  },

  watch: {
    authLoadingStatus: {
      immediate: false,
      handler: function (newValue) {
        if (newValue === Constants.AUTH_LOADING_STATUSES.SUCCESS) {
          setTimeout(() => {
            this.$parent.close();
          }, 1500);
        } else if(newValue === Constants.AUTH_LOADING_STATUSES.ERROR) {
          this.$parent.close();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-card-head {
  min-height: 240px;
}

.success-icon {
  width: 56px;
  height: 56px;
}
</style>
