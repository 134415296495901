import { Constants } from "@/web/constants";

import InboxThreadModel from "@/web/store/models/InboxThreadModel";
import InboxMessageModel from "@/web/store/models/InboxMessageModel";

export const types = {
  LOAD_DATA: "loadInbox",
  LOAD_DATA_SUCCESS: "loadInboxSuccess",
  LOAD_DATA_ERROR: "loadInboxError",
};

export const state = {
  status: null,
  error: null,
  notificationCount: 0,
};

export const mutations = {
  [types.LOAD_DATA](state) {
    state.status = Constants.STATUS_LOADING;
  },
  [types.LOAD_DATA_SUCCESS](state) {
    state.status = Constants.STATUS_LOADED;
  },
  [types.LOAD_DATA_ERROR](state, error) {
    state.status = Constants.STATUS_ERROR;
    state.error = error;
  },
};

export const actions = {
  async clearInbox({commit}) {
    await InboxThreadModel.deleteAll();
    await InboxMessageModel.deleteAll();
    await commit("inboxMessages/resetMeta", null, {root: true});
    await commit("inboxThreads/resetMeta", null, {root: true});
  },
};

export const getters = {
  unreadThreads(state) {
    return InboxThreadModel.query()
      .where(thread => {
        return !thread.read_at || new Date(thread.read_at) < new Date(thread.updated_at);
      })
      .withAllRecursive()
      .orderBy(thread => -new Date(thread.updated_at))
      .all();
  },

  unreadMessagesCount(state) {
    return InboxThreadModel.query()
      .where(thread => {
        return !thread.read_at || new Date(thread.read_at) < new Date(thread.updated_at);
      })
      .count();
  },

  isLoaded(state) {
    return state.status === Constants.STATUS_LOADED;
  },
};

export default {
  namespaced: true,
  state: state,
  mutations,
  actions,
  getters,
};
