import Constants from "@/web/constants";
import FeedWallChannelModel from "@/web/store/models/FeedWallChannelModel";
import FeedWallCommentModel from "@/web/store/models/FeedWallCommentModel";
import FeedWallThreadModel from "@/web/store/models/FeedWallThreadModel";

export const types = {
  LOAD_CHANNELS: "loadChannels",
  LOAD_CHANNELS_SUCCESS: "loadChannelsSuccess",
  LOAD_CHANNELS_ERROR: "loadChannelsError",
};

export const state = () => ({
  statuses: {},
  errors: {},
});

export const mutations = {
  [types.LOAD_CHANNELS](state, componentId) {
    const statuses = {...state.statuses};
    statuses[componentId] = Constants.STATUS_LOADING;
    state.statuses = statuses;
  },
  [types.LOAD_CHANNELS_SUCCESS](state, componentId) {
    const statuses = {...state.statuses};
    statuses[componentId] = Constants.STATUS_LOADED;
    state.statuses = statuses;
  },
  [types.LOAD_CHANNELS_ERROR](state, {componentId, error}) {
    const statuses = {...state.statuses};
    const errors = {...state.errors};
    errors[componentId] = error;
    statuses[componentId] = Constants.STATUS_ERROR;
    state.statuses = statuses;
    state.error = errors;
  },
};

export const actions = {
  async fetchData({getters, rootGetters, dispatch}, {selectedChannelId, selectedThreadId, isUpdate}) {
    if (isUpdate) {
      if (selectedThreadId) {
        return dispatch("feedWallChannel/loadThread", {
          channelId: selectedChannelId,
          threadId: selectedThreadId
        }, {root: true}).then(
          () => {
            const areCommentsFetched = !!rootGetters["feedWallThread/getLastCommentTimestamp"](selectedThreadId);
            if (areCommentsFetched) {
              return dispatch(
                "feedWallThread/updateComments",
                {channelId: selectedChannelId, threadId: selectedThreadId},
                {root: true}
              );
            } else {
              return dispatch("feedWallThread/loadComments", {
                channelId: selectedChannelId,
                threadId: selectedThreadId
              }, {root: true});
            }
          }
        );
      } else if (selectedChannelId) {
        const areThreadsFetched = !!rootGetters["feedWallChannel/getLastThreadTimestamp"](selectedChannelId);
        if (areThreadsFetched) {
          return dispatch("feedWallChannel/updateThreads", {channelId: selectedChannelId}, {root: true});
        } else {
          return dispatch("feedWallChannel/loadThreads", {channelId: selectedChannelId}, {root: true});
        }
      } else {
        const feedWallModules = rootGetters["feedWallModules"];
        const feedWallFetchPromises = feedWallModules.map(module =>
          dispatch("feedWall/loadChannels", {componentId: module.component_id}, {root: true})
        );
        return Promise.all(feedWallFetchPromises);
      }
    } else {
      const feedWallModules = rootGetters["feedWallModules"];
      const feedWallFetchPromises = feedWallModules.map(module =>
        dispatch("feedWall/loadChannels", {componentId: module.component_id}, {root: true})
      );
      return Promise.all(feedWallFetchPromises).then(() => {
        if (selectedThreadId) {
          return dispatch("feedWallChannel/loadThread", {
            channelId: selectedChannelId,
            threadId: selectedThreadId
          }, {root: true}).then(
            () => {
              const areCommentsFetched = !!rootGetters["feedWallThread/getLastCommentTimestamp"](selectedThreadId);
              if (areCommentsFetched) {
                return dispatch(
                  "feedWallThread/updateComments",
                  {channelId: selectedChannelId, threadId: selectedThreadId},
                  {root: true}
                );
              } else {
                return dispatch(
                  "feedWallThread/loadComments",
                  {channelId: selectedChannelId, threadId: selectedThreadId},
                  {root: true}
                );
              }
            }
          );
        } else if (selectedChannelId) {
          const areThreadsFetched = !!rootGetters["feedWallChannel/getLastThreadTimestamp"](selectedChannelId);
          if (areThreadsFetched) {
            return dispatch("feedWallChannel/updateThreads", {channelId: selectedChannelId}, {root: true});
          } else {
            return dispatch("feedWallChannel/loadThreads", {channelId: selectedChannelId}, {root: true});
          }
        } else {
          return Promise.resolve();
        }
      });
    }
  },

  async loadChannels({commit, state, getters, rootState}, {componentId}) {
    if (!getters.getIsLoading(componentId)) {
      commit(types.LOAD_CHANNELS, componentId);
      const eventId = rootState.eventId;
      const localChannelIds = getters.getChannelsFromComponent(componentId).map(it => it.id);

      return await FeedWallChannelModel.api()
        .get(`events/${eventId}/components/${componentId}/feed_wall`, {
          dataTransformer: ({data, headers}) => {
            const feedWallChannels = data;
            feedWallChannels.forEach(channel => {
              channel.event_component_id = componentId;
            });
            return feedWallChannels;
          },
        })
        .then(result => {
          const remoteChannelIds = result.response.data.map(channel => channel.id);
          let idsToDelete = localChannelIds.filter(channelId => !remoteChannelIds.includes(channelId));
          if (idsToDelete.length) {
            FeedWallChannelModel.delete(channel => idsToDelete.includes(channel.id));
          }
          commit(types.LOAD_CHANNELS_SUCCESS, componentId);
        })
        .catch(err => {
          commit(types.LOAD_CHANNELS_ERROR, {componentId: componentId, error: err});
        });
    } else {
      return Promise.resolve();
    }
  },

  async deleteChannel({state}, {channelId}) {
    return FeedWallChannelModel.delete(channelId);
  },

  async clearFeedWall({state}) {
    return Promise.all([FeedWallCommentModel.deleteAll(), FeedWallThreadModel.deleteAll(), FeedWallChannelModel.deleteAll()]);
  },
};

export const getters = {
  getChannelsFromComponent: state => componentId => {
    // eslint-disable-next-line
    return FeedWallChannelModel
      .query()
      .where("event_component_id", componentId)
      .withAllRecursive()
      .all();
  },

  getAllChannels: state => {
    // eslint-disable-next-line
    return FeedWallChannelModel
      .query()
      .withAllRecursive()
      .all();
  },

  getChannelById: state => channelId => {
    // eslint-disable-next-line
    return FeedWallChannelModel
      .query()
      .where(channel => channel.id === channelId)
      .withAllRecursive()
      .first();
  },

  getIsLoading: state => componentId => state.statuses[componentId] === Constants.STATUS_LOADING,

  getIsAnyLoading: state => {
    return !!Object.values(state.statuses).find(it => it === Constants.STATUS_LOADING);
  },

  getError: state => componentId => state.errors[componentId],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
