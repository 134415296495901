let auth2;
let googleUser;

export async function init(clientId, OnAuthSuccess, OnAuthError) {
  return loadGoogleSingleton(clientId).then(() => {
    return attachClickHandler(OnAuthSuccess, OnAuthError);
  });
}

export async function logout() {
  return new Promise(resolve => {
    if(googleUser) {
      googleUser.disconnect();
      googleUser = null;
    }
    resolve();
  });
}

//gapi should be loaded only once
function loadGoogleSingleton(clientId) {
  if (!loadGoogleSingleton.promise) {
    loadGoogleSingleton.promise = loadGoogleScript(clientId);
  }

  return loadGoogleSingleton.promise;
}

function loadGoogleScript(clientId) {
  const script = document.createElement("script");
  script.src = "https://apis.google.com/js/api:client.js";
  script.crossOrigin = "anonymous";
  script.async = true;
  script.defer = true;

  document.body.appendChild(script);

  return new Promise((resolve, reject) => {
    script.onload = () => resolve();
    script.onerror = () => reject(new Error(`Failed to load google script`));
  }).then(result => {
    return initGoogle(clientId);
  });
}

function initGoogle(clientId) {
  return new Promise(resolve => {
    window.gapi.load("auth2", () => {
      // eslint-disable-next-line no-undef
      auth2 = window.gapi.auth2.init({
        client_id: clientId,
        cookiepolicy: "single_host_origin",
        scope: "profile email",
      });
      resolve();
    });
  });
}

export async function attachClickHandler(onAuthSuccess, onAuthFail) {
  return new Promise((resolve, reject) => {
    if (auth2) {
      //this element is located in SocialLoginFooter.vue
      auth2.attachClickHandler(
        document.getElementById("ma-google-sign-in-button"),
        {},
        user => {
          googleUser = user;
          onAuthSuccess(googleUser.getAuthResponse(true).id_token);
        },
        onAuthFail
      );
      resolve();
    } else {
      reject(new Error("gapi auth2 is not initialized"));
    }
  });
}

export default {
  init,
  logout,
};
