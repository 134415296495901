import {Model} from "@vuex-orm/core";
import AttachmentModel from "./AttachmentModel";

export default class PartnerContactPersonModel extends Model {
  static entity = "partner_contact_persons";

  static fields() {
    return {
      id: this.attr(null),
      order: this.number(-1),
      first_name: this.string(""),
      last_name: this.string(""),
      description: this.string("").nullable(),
      phone: this.string("").nullable(),
      phone_country_code: this.string("").nullable(),
      email: this.string("").nullable(),
      picture_id: this.attr(null),
      picture: this.belongsTo(AttachmentModel, "picture_id")
    };
  }
}
