import { Model } from "@vuex-orm/core";
import UserModel from "@/web/store/models/UserModel";

export default class InboxMessageModel extends Model {
  static entity = "inbox_messages";

  static fields() {
    return {
      id: this.attr(null),
      user_id: this.attr(null),
      user: this.belongsTo(UserModel, "user_id"),
      inbox_thread_id: this.attr(null),
      message: this.string(""),
      created_at: this.string(""),
      updated_at: this.string("")
    };
  }
}
