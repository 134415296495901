import axios from "@/web/services/axios";
import Pusher from "pusher-js";
import { Constants } from "@/web/constants";

const APP_KEY = process.env.VUE_APP_PUSHER_API_KEY;
const APP_CLUSTER = "eu";

export const types = {
  LOAD_DATA: "loadDiscussion",
  LOAD_DATA_SUCCESS: "loadDiscussionSuccess",
  LOAD_DATA_ERROR: "loadDiscussionError"
};

export const state = {
  isFallback: false,
  status: null,
  error: null,
  session: null,
  questions: []
};

export const getters = {
  isLoaded(state) {
    return state.status === Constants.STATUS_LOADED;
  },
  isFallback(state) {
    return state.isFallback;
  }
};

export const mutations = {
  [types.LOAD_DATA](state) {
    state.status = Constants.STATUS_LOADING;
  },
  [types.LOAD_DATA_SUCCESS](state) {
    state.status = Constants.STATUS_LOADED;
  },
  [types.LOAD_DATA_ERROR](state, error) {
    state.status = Constants.STATUS_ERROR;
    state.error = error;
  },
  setSession(state, session) {
    state.session = session;
  },
  setQuestions(state, questions) {
    state.questions = questions;
  },
  setFallback(state, isFallback) {
    state.isFallback = isFallback;
  }
};

export const actions = {
  async loadAgendaSession({ commit }, { eventId, componentId, sessionId }) {
    const url = `/events/${eventId}/components/${componentId}/agenda_sessions`;

    commit(types.LOAD_DATA);
    return axios.get(url).then(
      ({ data }) => {
        const foundSession = data.find(session => session.id === sessionId);
        commit("setSession", foundSession);
        return Promise.resolve(foundSession);
      },
      err => {
        commit(types.LOAD_DATA_ERROR, err);
      }
    );
  },

  async loadQuestions({ commit }, { eventId, componentId, sessionId }) {
    const url = `/events/${eventId}/components/${componentId}/agenda_session/${sessionId}/discussion/questions`;

    return axios.get(url).then(
      ({ data }) => {
        const questions = data.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at));
        commit("setQuestions", questions);
        commit(types.LOAD_DATA_SUCCESS);
        return Promise.resolve(questions);
      },
      err => {
        commit(types.LOAD_DATA_ERROR, err);
      }
    );
  },

  async startPusherListening({ commit, dispatch }, { eventId, componentId, sessionId }) {
    const socket = new Pusher(APP_KEY, {
      cluster: APP_CLUSTER
    });

    socket.connection.bind("connected", () => dispatch("stopFallbackContinuousLoading"));
    socket.connection.bind("error", () => dispatch("startFallbackContinuousLoading", { eventId, componentId, sessionId }));

    const channelId = `event-${eventId}`;
    const channel = socket.subscribe(channelId);

    channel.bind("component-updated", ev => {
      if (ev.component_id === componentId && ev.item_id === sessionId) {
        dispatch("loadQuestions", { eventId, componentId, sessionId });
      }
    });

    return socket;
  },

  async startFallbackContinuousLoading({ commit, dispatch }, { eventId, componentId, sessionId }) {
    await commit("setFallback", true);
    dispatch("continuousLoading", { eventId, componentId, sessionId });
  },

  async stopFallbackContinuousLoading({ commit }) {
    await commit("setFallback", false);
  },

  async continuousLoading({ getters, dispatch }, { eventId, componentId, sessionId }) {
    dispatch("loadQuestions", { eventId, componentId, sessionId });
    if (getters.isFallback) {
      setTimeout(() => dispatch("continuousLoading", { eventId, componentId, sessionId }), 2500);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
