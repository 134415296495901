export const types = {
  SET_UTM_PARAMETERS: "setUTMParameters",
};

export const state = () => ({
  utmParameters: {},
});

export const mutations = {
  [types.SET_UTM_PARAMETERS](state, utmParameters) {
    state.utmParameters = utmParameters;
  },
};

export const actions = {};

export const getters = {
  utmParameters(state) {
    return state.utmParameters;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};