import { Model } from "@vuex-orm/core";
import AttachmentModel from "./AttachmentModel";

export default class EventModel extends Model {
  static entity = "events";

  static fields() {
    return {
      id: this.attr(null),
      title: this.string(""),
      description: this.string(""),
      access_mode_type: this.string(null),
      access_code_on: this.boolean(false),
      start_date: this.string(""),
      end_date: this.string(""),
      place_address: this.string(""),
      lon: this.number(-1),
      lat: this.number(-1),
      qrcode: this.string(""),
      main_color: this.string(""),
      main_text_color: this.string(""),
      menu_item_text_color: this.string(""),
      selected_menu_item_text_color: this.string(""),
      selected_menu_item_background_color: this.string(""),
      timezone: this.string(""),
      logo_id: this.attr(null),
      logo: this.belongsTo(AttachmentModel, "logo_id"),
      banner_id: this.attr(null),
      banner: this.belongsTo(AttachmentModel, "banner_id"),
      lp_banner_id: this.attr(null),
      lp_banner: this.belongsTo(AttachmentModel, "lp_banner_id"),
      social_media_login: this.boolean(false),
      website: this.string(null).nullable(),
      phone: this.string(null).nullable(),
      phone_country_code: this.string(null).nullable(),
      email: this.string(null).nullable(),
      facebook_profile: this.string(null).nullable(),
      twitter_profile: this.string(null).nullable(),
      youtube_profile: this.string(null).nullable(),
      instagram_profile: this.string(null).nullable(),
      linked_profile: this.string(null).nullable(),
      entrance_with_tickets: this.boolean(false),
      lp_settings: this.string(""),
      lp_url: this.string(""),
      google_shop_app_id: this.string(""),
      apple_shop_app_id: this.string(""),
      apple_bundle_id: this.string(""),
      marketing_enabled: this.boolean(false),
      marketing_discount_percentage: this.number(null).nullable(),
      tax: this.number(23),
      terms_of_sale_url: this.string(null).nullable(),
    };
  }

  static fetchByUrlSuffix(urlSuffix) {
    return this.api().get(`events/lp/${urlSuffix}`)
      .then(result => result.response.data);
  }
}
