import { Constants } from "@/web/constants";
import PartnerCategoryModel from "@/web/store/models/PartnerCategoryModel";

export const state = () => ({
  slug: null,
  status: null,
  error: null
});
export const types = {
  LOAD_PARTNERS: "loadPartners",
  LOAD_PARTNERS_SUCCESS: "loadPartnersSuccess",
  LOAD_PARTNERS_ERROR: "loadPartnersError"
};
export const mutations = {
  [types.LOAD_PARTNERS](state) {
    state.status = Constants.STATUS_LOADING;
  },
  [types.LOAD_PARTNERS_SUCCESS](state) {
    state.status = Constants.STATUS_LOADED;
  },
  [types.LOAD_PARTNERS_ERROR](state, error) {
    state.status = Constants.STATUS_ERROR;
    state.error = error;
  }
};
export const actions = {
  fetch({ commit }, { eventId, componentId }) {
    commit(types.LOAD_PARTNERS);
    PartnerCategoryModel.api()
      .get(`events/${eventId}/components/${componentId}/partner/categories`)
      .then(partners => {
        commit(types.LOAD_PARTNERS_SUCCESS);
      })
      .catch(err => {
        commit(types.LOAD_PARTNERS_ERROR, err);
      });
  }
};
export const getters = {
  getById: state => partnerId => {
    return PartnerCategoryModel.query()
      .where("id", partnerId)
      .withAllRecursive()
      .get()[0];
  },
  allPartnersCategories: state => componentId => {
    if (componentId) {
      return PartnerCategoryModel.query()
        .where("event_component_id", componentId)
        .orderBy("order")
        .withAllRecursive()
        .get();
    }
    return [];
  },
  isLoading(state) {
    return state.status === Constants.STATUS_LOADING;
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
