import {Model} from "@vuex-orm/core";
import UserModel from "@/web/store/models/UserModel";

export default class FeedWallCommentModel extends Model {
  static entity = "feed_wall_comments";

  static fields() {
    return {
      id: this.attr(null),
      message: this.string(""),
      uuid: this.string(""),
      user_id: this.attr(null),
      user: this.belongsTo(UserModel, "user_id"),
      created_at: this.string(""),
      updated_at: this.string(""),
      feed_wall_thread_id: this.attr(null),
    };
  }
}
