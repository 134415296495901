import {detectBrowserLanguage} from "@/shared/utils";

function installInterceptors(axios, store) {
  const interceptRequest = createRequestInterceptor(store);

  // eslint-disable-next-line prettier/prettier
  axios.interceptors.request.use(interceptRequest);
}

function createRequestInterceptor(store) {
  const getCurrentLocale = () => store.getters["locales/currentLocale"];

  return async function (req) {
    const locale = getCurrentLocale();
    const localeCode = locale && locale.localeCode;
    req.headers["Accept-Language"] = localeCode || detectBrowserLanguage();
    return req;
  };
}

export function install(axios) {
  return function (store) {
    installInterceptors(axios, store);
  };
}

export default {
  install,
};
