import axios from "@/web/services/axios";
import {Constants} from "@/web/constants";
import UserModel from "@/web/store/models/UserModel";

const API_LIMIT = 20;
const API_LIMIT_SEARCH = 50;

export const types = {
  LOAD_FRIENDS: "loadFriends",
  LOAD_FRIENDS_SUCCESS: "loadFriendsSuccess",
  LOAD_FRIENDS_ERROR: "loadFriendsError",
};

export const state = () => ({
  status: false,
  error: null,
  lastFriendId: null,
  allItems: null,
  isMore: true,
  searchResult: null,
});

export const mutations = {
  setMeta(state, { meta, lastFriendId }) {
    state.allItems = meta.all_items;
    state.isMore = meta.is_more;
    state.lastFriendId = lastFriendId;
  },

  resetMeta(state) {
    state.lastUserId = null;
    state.allItems = null;
    state.isMore = true;
  },

  setSearchResult(state, foundFriends) {
    state.searchResult = foundFriends;
  },

  clearSearchResult(state) {
    state.searchResult = null;
  },

  [types.LOAD_FRIENDS](state) {
    state.status = Constants.STATUS_LOADING;
  },
  [types.LOAD_FRIENDS_SUCCESS](state) {
    state.status = Constants.STATUS_LOADED;
  },
  [types.LOAD_FRIENDS_ERROR](state, error) {
    state.status = Constants.STATUS_ERROR;
    state.error = error;
  },
};

export const actions = {
  async loadFriendsPage({ commit, state }) {
    const params = {
      limit: API_LIMIT,
      max_id: state.lastFriendId,
    };
    if (state.status !== Constants.STATUS_LOADING && state.isMore) {
      commit(types.LOAD_FRIENDS);
      return await UserModel.api()
        .get("friends", {
          params: params,
          dataTransformer: ({ data, headers }) => {
            const lastFriendId = data.users.slice(-1).pop().user_identity_token;
            commit("setMeta", { meta: data.meta, lastFriendId: lastFriendId });
            const users = data.users;
            for (const user of users) {
              user.is_friend = true;
            }
            return users;
          },
        })
        .then(result => {
          commit(types.LOAD_FRIENDS_SUCCESS);
        })
        .catch(err => {
          commit(types.LOAD_FRIENDS_ERROR, err);
        });
    } else {
      return Promise.resolve();
    }
  },

  async searchFriends({commit, state}, searchQuery) {
    commit(types.LOAD_FRIENDS);
    commit("setSearchResult", []);

    const params = {
      limit: API_LIMIT_SEARCH,
      search: searchQuery,
    };
    return await axios
      .get("friends/search", {
        params: params,
      })
      .then(result => {
        const foundFriends = result.data.users;
        commit("setSearchResult", foundFriends);
        commit(types.LOAD_FRIENDS_SUCCESS);
      })
      .catch(err => {
        commit(types.LOAD_FRIENDS_ERROR, err);
      });
  },
};

export const getters = {
  getFriends: state => searchQuery => {
    if (searchQuery.length) {
      return state.searchResult;
    } else {
      // eslint-disable-next-line
      return UserModel.query()
        .where("is_friend", true)
        .withAllRecursive()
        .orderBy(user => user.last_name.toLowerCase())
        .orderBy(user => user.first_name.toLowerCase())
        .all();
    }
  },

  isLoading: state => state.status === Constants.STATUS_LOADING,
};

export default {
  namespaced: true,
  state: state,
  mutations,
  actions,
  getters,
};
